import useRoles from 'common/hooks/useRoles';
import { hoursToMilliseconds } from 'date-fns';
import usePersistentQueryParams, {
  PersistentQueryParams,
} from 'common/hooks/usePersistentQueryParams';
import useRoleRedirect from 'hooks/url/useRoleRedirect';

import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Loader from 'styleguide/src/components/Loader';
import Warning from 'styleguide/src/components/Warning/Warning';

function Home() {
  const { role, isLoading, error, isAuthenticated } = useRoles();
  const router = useRouter();
  const { redirect } = useRoleRedirect();
  const { isReady } = usePersistentQueryParams([
    {
      key: PersistentQueryParams.BRANDED_COMPANY_DATA,
      expiry: hoursToMilliseconds(48),
    },
  ]);

  useEffect(() => {
    if (isReady) {
      if (!isLoading && role && isAuthenticated) {
        redirect(role);
      }
      if (!isAuthenticated && !isLoading && !error) {
        router.push({ pathname: '/login' });
      }
    }
  }, [router, error, isLoading, role, isAuthenticated, isReady, redirect]);

  if (isAuthenticated && error) {
    return (
      <Warning
        full
        description="Unable to communicate with server. Please try again."
      />
    );
  }

  return <Loader fullscreen />;
}

export default Home;
