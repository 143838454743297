import { AccountRole } from 'common/types/AccountRole';
import { getItemWithExpiry } from 'common/utils/localStorage';
import { DEFAULT_ROUTE } from 'mappings/routes';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { AllowedRoles } from 'types/roles';
import {
  PersistentQueryParams,
  OpportunityApplicationData,
} from 'common/hooks/usePersistentQueryParams';
import { vendorOpportunityDetailsPath } from 'mappings/routes/vendor/opportunity';

const useRoleRedirect = () => {
  const router = useRouter();

  const redirect = useCallback(
    (role: AccountRole) => {
      let defaultLoggedInRoute = DEFAULT_ROUTE[role as AllowedRoles] ?? '/';
      const opportunityData = getItemWithExpiry<OpportunityApplicationData>(
        PersistentQueryParams.OPPORTUNITY_APPLICATION_DATA,
      );

      if (opportunityData && role === AccountRole.VENDOR) {
        defaultLoggedInRoute = vendorOpportunityDetailsPath(
          opportunityData.opportunityId,
          opportunityData.companyId,
        );
      }

      router.replace(defaultLoggedInRoute);
    },
    [router],
  );

  return {
    redirect,
  };
};

export default useRoleRedirect;
